@import "base/mixins";
@import "base/fonts_variables";
@import "base/colors_variables";
@import "base/animation_variables";

@import "fonts";

* {
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  background: $lightest-grey;
}

a {
  text-decoration: none;
}

.app {
  @include flexbox(stretch, flex-start, row);
  min-height: 100vh;
}

.main {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: 'Stolzl-Regular', 'Open Sans', verdana, arial, sans-serif;
  max-width: 1920px;
  transition: margin-left $sidebar_animation_duration, width $sidebar_animation_duration;

  &.main-with-sidebar {
    width: calc(100% - $close_sidebar_width);
  }
}

*:disabled, .disabled {
  opacity: 0.5;
  pointer-events: none;
}

.container {
  padding: 20px 30px;
}

@import "Popup";
@import "CustomCheckbox";
@import "CustomFormElements";
@import "CustomTooltip";
@import "Buttons";
@import "Tags";
@import "Header";
@import "Sidebar";
@import "Breadcrumbs";
@import "Tables";
@import "ProgressBar";
@import "FilterPanel";
@import "DevicePage";
@import "Login";
@import "NotFoundPage";
