.popup {
  @include flexbox(center, center);
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  .popup-body, .login-form_block {
    width: 600px;
    background: $white;
    box-shadow: 0px 5px 15px rgba(185, 185, 202, 0.5);
    border-radius: 10px;
    padding: 40px 40px 40px 40px;
    position: relative;

    &.delete-popup {
      @include flexbox(center, center, column);

      .popup-body_comment {
        margin: 0;
        text-align: center;
        @include font-style($primary-font-bold, 700, 20px);
        color: #42454E;
      }
    }

    &_title p {
      @include font-style($primary-font-bold, 700, 20px, 23px);
      text-transform: uppercase;
      color: $dark-grey;
      margin-bottom: 30px;
    }

    .center p {
      text-align: center;
    }

    &_comment {
      @include font-style($primary-font-semibold, 500, 18px, 26px);
      color: $dark-grey;
      margin: 30px 0;
    }

    .close-btn {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    .popup-form_item {
      background: $lightest-grey;
      box-shadow: $shadow;
      border-radius: 10px;
      @include flexbox(center, space-between);
      padding: 20px 20px 20px 20px;
      margin-bottom: 5px;
      position: relative;

      &.popup-form_multiline {
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
      }

      &-name-with-hint {
        @include flexbox( center, flex-start);

        .popup-form_item-hint {
          margin-left: 7px;
        }
      }

      &-comment {
        a {
          color: #1F92C3;
          text-decoration: underline;
          text-transform: capitalize;
          cursor: pointer;
        }
      }

      p {
        @include font-style($primary-font-bold, 700, 14px, 16px);
        color: $grey;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

      .form-input, .custom-select {
        width: 345px;
      }

      textarea.form-input {
        height: 82px;
        width: 100%;
        resize: none;
      }
    }

    .form-error-message {
      color: $red;
      font-size: 11px;
      position: absolute;
      right: 12px;
      bottom: -6px;
    }

    .popup-form_map-list {
      border: 10px solid #F4F6FA;
      border-radius: 5px;

      &_item {
        background: transparent;
        @include font-style($primary-font-semibold, 500, 16px, 10px);
        color: $grey;
        padding: 15px;
        border-bottom: 1px solid #F4F6FA;
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      &_item.active {
        background: #F4F6FA;
      }
    }

    .popup-form_empty {
      p {
        @include font-style($primary-font-semibold, 400, 16px, 22px);
        color: $grey;
      }

      .link {
        @include font-style($primary-font-semibold, 700, 16px, 22px);
        color: $blue;
      }
    }

    .form-btn {
      margin-top: 30px;
      @include flexbox(center, flex-start);

      .green-text {
        color: $dark-green;
      }

      &.inner-form-btn {
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    .save-btn, .cancel-btn, .delete-red-btn {
      padding: 10px 32px;
      margin-right: 30px;
    }

    .delete-popup {
      @include flexbox(center, center, column);

      &_assigned-devices {
        @include flexbox(flex-start, flex-start, column);

        p {
          text-align: left;
          @include font-style($primary-font-bold, 700, 20px);
          color: #42454E;
        }

        .disabled {
          opacity: .5;
        }
      }

      .center {
        align-items: center;
      }

      .remove-title {
        text-align: center;
        @include font-style($primary-font-bold, 700, 20px);
        color: #42454E;
      }

      .devices-list {
        width: 400px;
        margin-top: 30px;
        @include flexbox(stretch, center, column);

        .assigned-device {
          background: #F4F6FA;
          box-shadow: 0px -1px 2px #FFFFFF, 1px 1px 2px #CFCFCF;
          border-radius: 5px;
          padding: 16px 20px;
          margin-bottom: 6px;
          text-align: left;
          color: #6B7282;
          @include font-style($primary-font-semibold, 500, 16px, 10px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 590px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .delete-popup_assigned-devices {
        .devices-list {
          width: 100%;

          p:first-child {
            margin-bottom: 20px;
          }

          .form-btn {
            justify-content: center;
          }
        }
      }
    }
  }

  .popup-group {
    position: relative;

    &_title {
      @include font-style($primary-font-semibold, 500, 18px, 140%);
      color: $dark-grey;
      margin-bottom: 15px;
    }

    &_body {
      @include flexbox(space-between, flex-start, row, wrap);
      @include font-style($primary-font-semibold, 500, 16px, 19px);
      margin-bottom: 20px;
    }

    &_error-message {
      color: $red;
      font-size: 11px;
      position: absolute;
      right: 12px;
      bottom: -6px;
    }
  }

  .settings-popup {
    &_body {
      .custom-checkbox-block {
        flex-basis: 30%;
        padding: 6px;

        .custom-checkbox + label {
          @include font-style($primary-font-semibold, 600, 14px, 19px);
          color: $dark-grey;
        }

        .custom-checkbox + label::before {
          box-sizing: border-box;
          width: 30px;
          height: 30px;
          background-size: 55%;
        }
      }
    }
  }

  .map-popup, .sample-block, .configurations-popup {
    .map-popup_inputs {
      margin-bottom: 30px;

      &:first-child {
        margin-bottom: 6px;
      }

      .popup-form_item {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .form-input {
        margin-left: 10px;
      }

      .small-input {
        input {
          width: 130px;
        }
      }

      &__file {
        @include flexbox(stretch, center, row);
        margin-top: 30px;
        &__name {
          padding: 0px 10px 0px 20px;
          background-color: #F4F6FA;
          box-shadow: 0px -1px 2px #FFFFFF, 1px 1px 2px #CFCFCF;
          border-radius: 5px;
          width: 100%;
          margin-right: 6px;
          @include flexbox(center, flex-start, row);
        }
        p {
          @include font-style($primary-font-semibold, 600, 16px, 19px);
          color: $grey;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 290px;
        }
        span {
          display: block;
          width: 20px;
          height: 20px;
          background: url("./assets/img/selected-with-bg.png") no-repeat center;
        }
        .delete-red-btn {
          margin-right: 0;
        }
      }
      &__configuration-devices {
        margin-top: 30px;
        height: 316px;
        overflow-y: auto;
        .text {
          @include font-style($primary-font-semibold, 700, 14px, 16px);
          color: $grey;
          margin-bottom: 25px;
        }
        .map-popup_inputs__file {
          margin-top: 5px;
          p {
            @include font-style($primary-font-semibold, 700, 14px, 16px);
          }
        }
      }
      .search-select-field {
        background: $lightest-grey;
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        color: $grey;
        @include font-style($primary-font-semibold, 600, 14px, 16px);
      }
    }

    .successful-block {
      margin-top: 30px;
      p {
        @include font-style($primary-font-semibold, 600, 16px);
        color: #0FC18C;
      }
    }
    .errors-block {
      height: 287px;
      overflow-y: auto;
      margin-top: 25px;
      p {
        @include font-style($primary-font-semibold, 700, 16px, 19px);
        color: $dark-grey;
        margin: 0 0 30px 0;
      }
      .link {
        @include font-style($primary-font-semibold, 700, 16px, 19px);
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
      }
      .error-code {
        padding-left: 60px;
        margin-bottom: 15px;
        background: url("./assets/img/error-triangle.svg") no-repeat center left;
        min-height: 25px;
        @include flexbox(center, flex-start, row);
        p {
          @include font-style($primary-font-semibold, 500, 14px);
          color: #E45B55;
          margin: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .errors-map-block {
      margin-top: 20px;
      .error-code {
        padding-left: 60px;
        margin-bottom: 15px;
        background: url("./assets/img/warning-triangle.svg") no-repeat center left;
        min-height: 25px;
        @include flexbox(center);
        p {
          @include font-style($primary-font-semibold, 500, 14px);
          color: #FF991F;
          margin: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .popup-map_children {
      @include flexbox(center, center, column);
      border: 2px dashed rgba(107, 114, 130, 0.3);
      border-radius: 10px;
      cursor: pointer;
      padding: 40px 0;

      &_error {
        @include flexbox(center, center, column);
        margin-bottom: 20px;

        .popup-map_children__title, .popup-map_children__formats {
          color: $red;
        }
      }

      &__title {
        @include font-style($primary-font-semibold, 700, 16px, 19px);
        text-transform: uppercase;
        color: $grey;
        margin-top: 10px;
        margin-bottom: 6px;
      }

      &__subtitle {
        @include font-style($primary-font-semibold, 700, 14px, 16px);
        color: $grey;
        margin-bottom: 15px;
        margin-top: 10px;
      }

      &__formats {
        @include font-style($primary-font-semibold, 500, 12px, 14px);
        color: $grey;

        &.size-format {
          margin-top: 20px;
        }
      }

      .blue-block {
        background: linear-gradient(117.19deg, #1FA1E9 0%, #01E5FF 97.52%);
        border-radius: 5px;
        padding: 12px 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        p {
          @include font-style($primary-font-semibold, 700, 14px, 16px);
          text-transform: uppercase;
          color: $white;
          margin: 0;
        }

        img {
          margin-left: 8px;
        }
      }
    }
  }

  .dialog-popup {
    width: 425px;
    padding: 40px;
    text-align: center;

    .popup-body_comment {
      margin: 0 auto;
      word-wrap: break-word;
      line-height: 23px;
    }

    .form-btn {
      justify-content: center;

      .cancel-btn {
        margin-right: 0;
      }
    }
  }
  .device-popup {
    min-height: 694px;
    width: 540px;
    .errors-block {
      height: 108px;
    }
  }
  .configurations-popup {
    width: 500px;
  }
}
.confirmed-popup {
  background: $lighter-grey;
  box-shadow: 0px 5px 15px rgba(185, 185, 202, 0.5);
  border-radius: 10px;
  position: fixed;
  bottom: 35px;
  left: 50%;
  margin-left: -211px;
  z-index: 10;
  width: 360px;
  padding: 20px 32px 20px 30px;
  &_body {
    @include flexbox(center, space-between);
  }
  .info-block {
    @include flexbox(center, flex-start);
    p {
      @include font-style($primary-font-semibold, 700, 14px, 16px);
      color: $grey;
      margin: 0;
    }
    .numbers {
      padding: 5px 15px;
      background: $lightest-grey;
      box-shadow: inset 0px -1px 0px #FAFAFA, inset 0px 1px 6px rgba(217, 218, 219, 0.8);
      border-radius: 3px;
      margin-right: 12px;
    }
  }
  .btn-block {
    @include flexbox(center, flex-end);
    .save-btn {
      padding: 10px 20px;
      margin-right: 30px;
    }
  }
}
.confirmed-popup-warning {
  width: 960px;
  margin-left: -480px;
  background: #FFFAE6;
  .info-block {
    .text {
      line-height: 22px;
      max-width: 670px;
      padding-left: 45px;
      background: url("./assets/img/warning-triangle.svg") no-repeat center left;
    }
    .numbers {
      margin-right: 0;
    }
  }
}