.search-panel {
    @include flexbox(stretch, flex-start, column);
    width: 100%;
    height: auto;
    max-width: 1100px;
    position: relative;
    padding: 10px 20px;
    margin: 0 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: none;
    overflow: visible;
    transition: box-shadow linear;
    transition-delay: $filter_panel_animation_duration;

    .search-block {
        @include flexbox(center, center);
        background-color: $lighter-grey;
        border-radius: 10px;
        padding: 10px 20px;

        .column-search {
            @include font-style($primary-font-semibold, 500, 16px, 19px);
            color: $grey;
            border: none;
            outline: none;
            background-color: inherit;
            color: $dark-grey;
            width: 100%;
            margin-right: 10px;
            
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &::placeholder {
                opacity: 0.3;
            }
        }

        .search-block_buttons {
            @include flexbox(center, space-between);

            .search-btn {
                width: 19px;
                height: 19px;
                background: url("./assets/img/search.svg") no-repeat center/contain;
            }

            .pipe {
                width: 2px;
                height: 36px;
                margin: 0 18px;
                background: url("./assets/img/pipe.svg") no-repeat center/contain;
            }

            .filter-btn {
                @include font-style($primary-font-semibold, 700, 14px, 16px);
                color: $dark-grey;
                @include flexbox(center, center);
                height: 20px;
                background-color: inherit;
                text-transform: capitalize;

                &_icon {
                    margin-left: 10px;
                }

                &.filter-btn_active {
                    color: $blue;
                }

                &.filter-btn__close {
                    width: max-content;
                    .filter-btn_icon {
                        width: 12px;
                        height: 12px;
                    }
                }

                .filter-count {
                    @include flexbox(center, center);
                    background: $blue;
                    height: 20px;
                    width: 20px;
                    color: $white;
                    border-radius: 50%;
                    margin-left: 10px;
                    font-size: 12px;
                }
            }

            .clear-filter-btn {
                padding: 10px 32px;
                color: $dark-grey;
            }
        }
    }

    .filter-panel {
        overflow: hidden;
        height: auto;
        max-height: 0px;
        transition: max-height $filter_panel_animation_duration ease-out;

        .filter-panel_content {
            @include flexbox(center, space-between, row);
            margin-top: 10px;
            padding: 2px;
            
            .filters {
                @include flexbox(center, flex-start, row, wrap);
                gap: 10px;
            }
        }
    }

    @keyframes delay-overflow {
        from { overflow: hidden; }
    }

    &.search-panel_active-filter {
        box-shadow: $block-shadow;
        transition-delay: 0s;

        .filter-panel {
            overflow: visible;
            max-height: 300px;
            transition: max-height $filter_panel_animation_duration ease-in;
            animation: 3s delay-overflow;
        }
    }
}

input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }