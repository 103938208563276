.tags-item {
  @include font-style($primary-font-semibold, 500, 14px, 16px);
  color: $dark-grey;
  padding: 15px 20px;
  box-shadow: $shadow;
  border-radius: 5px;
}
.color-0 {
  background: #B9EDE4;
}
.color-1 {
  background: #FFBFBC;
}
.color-2 {
  background: #DCDCFF;
}
.color-3 {
  background: #F0F1BD;
}
.color-4 {
  background: #FFD89E;
}