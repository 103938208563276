@mixin font-face($name, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: $name;
        src: url($path) format("ttf");
        font-weight: $weight;
        font-style: $style;
        unicode-range: U+0020-00FE;
    }
}

@mixin font-style($family, $weight, $size, $height: normal) {
    font-family: $family;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
}

@mixin flexbox($alignItems: stretch, $justifyContent: flex-start, $direction: row, $wrap: nowrap) {
    display: flex;
    align-items: $alignItems;
    justify-content: $justifyContent;
    flex-direction: $direction;
    flex-wrap: $wrap;
}