.form-element {
    box-sizing: border-box;
    color: $grey;
    box-shadow: inset 0px -1px 0px $darker-white, inset 0px 1px 1px rgba(217, 218, 219, 0.3);
    @include font-style($primary-font-semibold, 500, 16px);
    border-radius: 5px;
    width: 100%;
    padding: 0 15px 0 15px;
    background-color: $white;
    border: 1px solid transparent;
    outline: none;

    &.error {
        outline: 1px solid $red;
    }

    &+.error-message {
        color: $red;
        font-size: 10px;
        position: absolute;
        right: 12px;
        bottom: 4px;
    }
}

.form-input {
    @extend .form-element;
    height: 40px;
    padding: 15px;
}

.custom-select-block {
    .custom-select {
        @extend .form-element;
        background: url("./assets/img/arow.png") $white no-repeat center right 10px;
        min-height: 40px;
        border: none;
        outline: none;
        padding-left: 15px;
        padding-right: 30px;
        appearance: none;
        position: relative;

        & > span {
            line-height: 40px;
            /*max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;*/
        }

        &__options {
            background-color: $white;
            z-index: 100;
            display: block;
            border: 1px solid $light-grey;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: absolute;
            box-sizing: border-box;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 300px;
            overflow-y: auto;

            &__search {
                position: relative;
                padding: 10px;
                & > .form-input {
                    width: 100%!important;
                }

                & > .search-select-field {
                    background: $lightest-grey;
                    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    color: $grey;
                    @include font-style($primary-font-semibold, 400, 14px, 16px);
                    padding: 15px 30px 15px 15px;
                }

                .clear-icon, .search-icon {
                    display: block;
                    position: absolute;
                    z-index: 5;
                    top: 24px;
                    right: 24px;
                    width: 12px;
                    height: 12px;
                    background: url("./assets/img/close-icon.png");
                    background-size: contain;
                    cursor: pointer;
                }
                .search-icon {
                    top: 22px;
                    right: 22px;
                    width: 14px;
                    height: 14px;
                    background: url("./assets/img/search-icon.svg");
                    cursor: text;
                }
            }
        }

        &__option {
            padding: 5px 30px 5px 15px;
            display: flex;
            align-items: center;
            padding: 6px 10px;
            cursor: pointer;
            span {
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                background-color: $lightest-grey;
            }

            &:last-child:hover {
                border-radius: 5px;
            }
        }

        .empty {
            color: #E45B55;
            &:hover {
                background-color: transparent;
            }
        }
    }

    .multi-select {
        .selected-values {
            padding: 4px 0;
            box-sizing: border-box;
            min-height: 40px;
            @include flexbox(center, flex-start, row, wrap);
            gap: 7px;

            .selected-value {
                @include flexbox(center, center);
                border-radius: 5px;
                padding: 0;

                &__value {
                    padding: 7px 0px 7px 10px;
                    max-width: 240px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__close {
                    height: 30px;
                    width: 30px;
                    background: url('./assets/img/close-icon.png') no-repeat center;
                    background-size: 10px 10px;

                    &:hover {
                        opacity: 0.3;
                    }
                }

            }
        }
    }

    .multi-select-with-checkbox {
        width: 300px;
        background-color: $lighter-grey;
        color: $grey;
        box-shadow: none;

        &.multi-select_opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &.multi-select_active {
                outline: none;
            }
        }

        &.multi-select_active {
            outline: 2px solid $blue;
        }

        .selected-values {
            min-width: 0;

            // &_title {
            //     font-weight: 600;
            // }
            &_value {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .custom-select__options {
            background-color: $lighter-grey;
            border: none;

            .custom-select__options-block {
                margin: 15px;

                .custom-select__option {
                    background-color: $lightest-grey;
                    padding: 10px;
                    box-shadow: 0px -1px 2px #FFFFFF, 1px 1px 2px #CFCFCF;
                    border-radius: 5px;
                    margin: 4px 0;
                    overflow: hidden;
                    label {
                        color: $grey;
                    }
                }
            }
        }
    }

    &.error {
        box-shadow: inset 0px 0px 1px $red;
        color: $red;

        option {
            color: $grey;
        }
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
}