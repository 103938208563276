.header {
  @include flexbox (center, space-between);
  padding: 0 50px 30px 50px;
  border-bottom: 2px solid $lighter-grey;
  .logo {
    margin-right: 50px;
    margin-top: -11px;
    img {
      width: 132px;
    }
  }
  .nav-btns {
    width: 100%;
    @include flexbox(stretch, center);
    margin-right: 60px;
  }
  .nav-link {
    @include flexbox(center, center);
    text-decoration: none;
    width: 235px;
    background: $lighter-grey;
    padding: 17px 0;
    color: $grey;
    @include font-style($primary-font-extrabold, 700, 14px);
    margin-right: 5%;
    box-shadow: $shadow;
    border-radius: 5px;
    &.active {
      color: $white;
      background: $blue-gradient;
      box-shadow: $blue-shadow;
    }
    //transition: .3s ease;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $white;
      background: $blue-gradient;
      box-shadow: $blue-shadow;
    }
    @media (max-width: 1640px) {
      width: 150px;
    }
  }
  .sing-out {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
}