.custom-checkbox-block {
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  label {
    @include font-style($primary-font-semibold, 600, 12px, 14px);
    color: $dark-grey;
    cursor: pointer;
  }
  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid $lighter-grey;
    box-shadow: inset 0px 1px 1px rgba(217, 218, 219, 0.3);
    border-radius: 2px;
    margin-right: 10px;
    background-color: $lighter-grey;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .custom-checkbox:checked + label::before {
    box-shadow: inset 0px 0px 1px rgba(217, 218, 219, 0.3);
    border-color: $grey;
    background-color: $grey;
    background-image: url("./assets/img/selected.png");
  }
}