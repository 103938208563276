.breadcrumbs {
  padding: 20px 30px 13px 30px;
  @include flexbox(flex-start, space-between);
  border-bottom: 2px solid $lighter-grey;

  & > * {
    height: 80px;
  }

  .title-block {
    @include flexbox(center, flex-start);
    flex: 1;
    img {
      margin: 0 15px;
    }
    p {
      @include font-style($primary-font-bold, 700, 20px, 23px);
      color: $dark-grey;
      white-space: nowrap;
    }
    .path {
      text-transform: uppercase;
      &.main-parent:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .current-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .button-block {
    flex: 1;
    @include flexbox(center, flex-end);

    .btn-add {
      border: none;
      padding: 15px 25px;
      color: $white;
      background: $blue-gradient;
      box-shadow: $blue-shadow;
      border-radius: 50px;
      text-transform: uppercase;
      @include font-style($primary-font-black, 900, 16px);
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      img {
        margin-left: 10px;
      }
    }
  }
}