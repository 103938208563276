$red: #e45b55;
$light-red: #fff9f8;
$green: #9bf39d;
$light-green: #EDF7ED;
$white: #ffffff;
$darker-white: #fafafa;
$black: #000000;
$lightest-grey: #f4f6fa;
$lighter-grey: #ededf5;
$light-grey: #cfcfcf;
$grey: #6b7282;
$dark-grey: #42454e;
$blue: #2babe1;
$dark-green: #0FC18C;
$orange: #F38648;

$shadow: 0px -1px 2px $white, 1px 1px 2px $light-grey;
$block-shadow: 0px 0px 10px $light-grey;

$blue-gradient: linear-gradient(117.19deg, #1fa1e9 0%, #01e5ff 97.52%);
$blue-shadow: 0px -2px 3px rgba(25, 175, 238, 0.2), 0px 1px 3px rgba(6, 218, 252, 0.4);
