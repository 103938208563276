.sidebar {
  @include flexbox (center, space-between, column);
  box-sizing: border-box;
  padding: 17px 10px; 
  width: $close_sidebar_width;
  background-color: $lighter-grey;
  box-shadow: $shadow;
  transition: $sidebar_animation_duration;

  .sidebar_content, .sidebar_footer {
    width: 100%;
  }

  .logo {
    display: block;
    max-width: 120px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 43px;
    overflow: hidden;
    transition: $sidebar_animation_duration;

    &-sign {
      width: 56px;
    }

    &-name {
      width: 0;
      transition: $sidebar_animation_duration;
      position: absolute;
      top: 20px;
      left: 35px;
    }
  }

  .menu {
    @include flexbox (center, flex-start);
    @include font-style($primary-font-extrabold, 800, 12px, 14px);
    color: $blue;
    text-transform: uppercase;
    margin-bottom: 20px;

    .btn-open {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: none;
      background: url('./assets/img/open-arrow.svg') no-repeat center;
      background-color: $blue;
      position: absolute;
      left: calc($close_sidebar_width - 10px);
      transition: left $sidebar_animation_duration;
      cursor: pointer;
    }
  }

  .nav-link {
    @include flexbox(center, flex-start);
    width: calc(100% - 20px);
    height: 40px;
    padding: 2px 10px;
    border-radius: 10px;
    margin-bottom: 12px;
    cursor: pointer;

    &.active {
      background: $lightest-grey;
      .nav-link_text {
        color: $dark-grey;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background: $lightest-grey;
    }

    img {
      vertical-align: middle;
    }

    &_text {
      display: inline-block;
      white-space: nowrap;
      @include font-style($primary-font-bold, 700, 12px, 14px);
      width: 0px;
      overflow: hidden;
      text-transform: uppercase;
      color: $grey;
      margin-left: 10px;
      transition-delay: 2s;
      transition-property: width;
    }
  }

  &_footer {
    .nav-link {
      &_text {
        text-transform: capitalize;
      }
    }
  }

  &_open {
    width: $open_sidebar_width;

    .logo {      
      .logo-name {
        width: 77px;
      }
    }

    .menu .btn-open {
      background-image: url('./assets/img/close-arrow.svg');
      left: calc($open_sidebar_width - 10px);
    }

    .nav-link {
      &_text {
        width: 100%;
        transition-delay: 0s;
        transition-property: width;
      }
    }

    & + .main.main-with-sidebar {
      width: calc(100% - $open_sidebar_width);
    }
  }
}