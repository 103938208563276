.btn {
  margin: 0;
  outline: none;
  text-transform: uppercase;
  font-family: $primary-font-bold;
  font-weight: 700;
  cursor: pointer;
  border: none;
}
.save-btn {
  background: $green;
  box-shadow: $shadow;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  color: $dark-grey;
  padding: 6px 20px;
  text-align: center;
  .progress-bar {
    height: 16px;
  }
}
.cancel-btn {
  background: transparent;
  color: $grey;
  font-size: 14px;
  line-height: 16px;
}
.table-btn {
  font-size: 14px;
  color: $dark-grey;
  padding: 10px 20px;
  background: $lighter-grey;
  box-shadow: $shadow;
  border-radius: 5px;
  cursor: pointer;
  &:first-child {
    margin-right: 8px;
  }
}
.delete-red-btn {
  background: $red;
  font-size: 14px;
  color: $white;
  box-shadow: $shadow;
  border-radius: 5px;
  padding: 8px 16px;
  color: $white
}
.next-blue-btn {
  background: linear-gradient(117.19deg, #1FA1E9 0%, #01E5FF 97.52%);;
  box-shadow: 0px -1px 2px #FFFFFF, 1px 1px 2px #CFCFCF;;
  border-radius: 5px;
  padding: 10px 20px;
  color: $white
}
.add-tag-btn {
  background-color: $white;
  background-image: url("./assets/img/plus-opacity.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border: 2px dashed rgba(107, 114, 130, 0.3);
  border-radius: 5px;
  padding: 21px 25px;
  margin: 0 0 10px 0;
}
.choose-btn {
  font-size: 12px;
  line-height: 14px;
  color: $dark-grey;
  text-transform: lowercase;
  min-width: 120px;
}
.close-btn {
  background: url('./assets/img/close-icon.png') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}
.edit-btn {
  img {
    margin-right: -2px;
  }
}
.create-config-btn {
  background-image: url('./assets/img/config.svg');
  background-size: 18px;
  background-position: center right 20px;
  background-repeat: no-repeat;
  padding: 10px 48px 10px 20px;
}