.tooltip {
    position: relative;
    display: inline-block;
    font-size: 0;
}

.tooltip .tooltiptext {
    position: absolute;
    height: 30px;
    width: max-content;
    @include font-style($primary-font-black, 600, 12px, 30px);
    visibility: hidden;
    background-color: $lightest-grey;
    color: $grey;
    padding: 0 10px;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(185, 185, 202, 0.5));
    z-index: 10;
}

.tooltip-arrow .tooltiptext::after {
    content: " ";
    position: absolute;
    border-width: 10px;
    border-style: solid;
}

.tooltip-top {
    .tooltiptext {
        transform: translate(-50%, -50%);
        bottom: 100%;
        left: 50%;
    }

    &.tooltip-arrow .tooltiptext::after {
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-color: $lightest-grey transparent transparent transparent;
    }
}

.tooltip-right {
    .tooltiptext {
        top: calc(50% - 15px);
        left: calc(100% + 10px);
    }

    &.tooltip-arrow .tooltiptext::after {
        top: 50%;
        right: 100%;
        margin-top: -10px;
        border-color: transparent $lightest-grey transparent  transparent;
    }
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}