.device-page {
  @include flexbox(stretch, space-between);
  padding: 30px 60px;
  @media (max-width: 1700px) {
    display: block;
  }
  .title {
    @include font-style($primary-font-extrabold, 800, 16px, 19px);
    text-transform: uppercase;
    color: $dark-grey;
    margin-bottom: 25px;
  }
  .device-item, .device-location {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .device-item, .asset-page {
    margin-right: 80px;
    width: 100%;
  }
  .asset-page, .device-item {
    max-width: 650px;
    @media (max-width: 1700px) {
      max-width: 100%;
    }
  }
  .device-info-block {
    background: $white;
    padding: 30px;
    border-radius: 10px;
    margin-bottom:  20px;
    @media (max-width: 1700px) {
      width: 100%;
      max-width: 650px;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
  .device-id-block {
    @include flexbox(center, space-between);
    margin-bottom: 20px;
  }
  .device-id {
    @include flexbox(center, center);
    max-width: 80%;
    .device-status {
      margin-right: 20px;
    }
    .device-name {
      @include font-style($secondary-font-medium, bold, 20px, 24px);
      overflow: hidden;
      word-wrap: break-word;
      color: $dark-grey;
    }
  }
  .device-btn {
    @include flexbox(center);
  }
  .device-info-data {

    @include flexbox(flex-start, flex-start, row);
    padding: 10px 0;

    &_name {
      flex: 1; 
      @include font-style($primary-font-bold, 700, 12px, 19px);
      text-transform: uppercase;
      color: $grey;
    }

    &_value {
      flex: 1;
      @include font-style($secondary-font-medium, 700, 16px, 19px);
      text-transform: uppercase;
      color: $grey;
      min-width: 0;
    }

    &_value-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 70%;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

  }
  .device-item-block {
    @media (max-width: 1700px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .device-location {
    @include flexbox(initial, flex-start, column);
    .btns-block {
      @include flexbox(stretch, flex-end, row);
    }
    img {
      width: 100%;
    }
    .img-block {
      @include flexbox(center, flex-start);
    }
    .empty-img {
      width: 100%;
      max-width: 390px;
    }
    .map-img {
      width: auto;
      max-width: 850px;
      max-height: 750px;
      @media (max-width: 1663px) {
        max-width: 850px;
      }
      @media (max-width: 1500px) {
        max-width: 700px;
      }
      @media (max-width: 1300px) {
        max-width: 650px;
      }
      @media (max-width: 1250px) {
        max-width: 615px;
      }
    }
  }
  .device-data-block {
    @include flexbox(stretch, space-between, row, wrap);
    width: 100%;
    .device-data {
      box-sizing: border-box;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: center top 18px;
      background-size: inherit;
      box-shadow: $shadow;
      border-radius: 10px;
      margin-top: 10px;
      flex-basis: 32%;
      padding: 15px;
      padding-top: 65px;
      text-align: center;

      .device-data_value {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: $grey;
        margin-bottom: 6px;
      }
      &.temperature {
        background-image: url("./assets/img/temperature.png");
      }
      &.co2_pressure_compensated {
        background-image: url("./assets/img/co2.png");

        &.ok-level {
          border: 3px solid $dark-green;
        }
        &.caution-level {
          border: 3px solid $orange;
        } 
        &.hazard-level {
          border: 3px solid $red;
        }
      }
      &.relative_humidity {
        background-image: url("./assets/img/humidity.png");
      }
      &.barometric_pressure {
        background-image: url("./assets/img/pressure.png");
      }
      &.rem_batt_capacity_display, &.rem_batt_capacity_sensor {
        background-image: url("./assets/img/battery.png");
      }
      .device-data_name-value {
        @include font-style($primary-font-semibold, 500, 16px, 19px);
        color: $grey;
      }
      // @media (max-width: 1428px) {
      //   max-width: 290px;
      // }
      // @media (max-width: 1360px) {
      //   max-width: 270px;
      // }
      // @media (max-width: 1315px) {
      //   max-width: 250px;
      // }
      // @media (max-width: 1260px) {
      //   max-width: 100%;
      // }
    }
    @media (max-width: 1700px) {
      width: 100%;
      max-width: 650px;
      margin-top: -10px;
    }
  }
  .device-tags-block {
    @include flexbox(center, flex-start, row, wrap);
    margin-top: 30px;
    .tags-item {
      margin-right: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .asset-attrs-buttons {
    @include flexbox(center, space-between);
    min-height: 40px;
    margin-bottom: 15px;
    .title {
      margin-bottom: 0;
    }
  }
  .asset-attributes-table {
    min-height: auto;
    overflow-y: auto;
    max-width: 650px;
  }
  .add-map-btn {
    padding-right: 40px;
    height: 40px;
    width: max-content;
    background-color: $lighter-grey;
    background-image: url("./assets/img/plus-black.png");
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
  .add-attr-btn {
    padding-right: 40px;
    height: 34px;
    width: max-content;
    background-color: $lightest-grey;
    background-image: url("./assets/img/plus-black.png");
    background-repeat: no-repeat;
    background-position: right 20px center;
  }
  .unassign-btn, .add-pin-btn, .change-pin-btn {
    padding-right: 40px;
    height: 40px;
    width: max-content;
    background-color: $white;
    border: 2px solid #EDEDF5;
    background-image: url("./assets/img/unassign.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    box-shadow: inherit;
    margin-left: 15px;
  }
  .add-pin-btn {
    background-image: url("./assets/img/add-pin.png");
  }
  .change-pin-btn {
    background-image: url("./assets/img/edit.png");
  }
  .cancel-pin-btn {
    height: 38px;
    width: 38px;
    background-color: $white;
    background-image: url("./assets/img/close-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #EDEDF5;
    border-radius: 5px;
    margin-right: 10px;
  }
  .save-pin-btn {
    height: 38px;
    width: 38px;
    background-color: $dark-green;
    background-image: url("./assets/img/white-selected.png");
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #EDEDF5;
    border-radius: 5px;
  }
  .block-title {
    background: $lighter-grey;
    box-shadow: $shadow;
    padding: 17px 40px;
    p {
      @include font-style($primary-font-bold, 700, 14px, 22px);
      color: $grey;
    }
  }
  .empty-block {
    @include flexbox(center, center);
    width: 100%;
  }
  .map-title-block {
    @include flexbox(center, space-between);
    min-height: 40px;
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
    }
  }
  .device-info {
    min-width: 340px;
    @media(max-width: 1560px) {
      min-width: 100%;
    }
  }
  .config-btn-block {
    @include flexbox(center, flex-end);
    @media(max-width: 1560px) {
      margin-top: 20px;
    }
  }
  .flexbox-block {
    @include flexbox(flex-end, space-between, row, wrap);
  }
}

.asset-page {
  .link {
    @include font-style($primary-font-extrabold, 700, 14px, 16px);
    color: #1F92C4;
    text-transform: capitalize;
    margin-top: 15px;
    cursor: pointer;
  }
  .asset-scroll-block {
    max-height: 700px;
    overflow-y: auto;
  }
  .device-info {
    min-width: 100%;
  }
  .device-info-data_value {
    flex: 2 1;
  }
}

.status-img {
  width: 23px;
  height: 23px;
  background: no-repeat center center;
  &.offline {
    background-image: url("./assets/img/offline.png");
  }
  &.online {
    background-image: url("./assets/img/online.png");
  }
  &.pending {
    background-image: url("./assets/img/pending.png");
  }
}

.battery-level-img {
  width: 36px;
  height: 16px;
  background: no-repeat center center;
  &.battery-full {
    background-image: url("./assets/img/battery-full.png");
  }
  &.battery-empty {
    background-image: url("./assets/img/battery-empty.png");
  }
  &.battery-medium-low {
    background-image: url("./assets/img/battery-medium-low.png");
  }
  &.battery-medium-high {
    background-image: url("./assets/img/battery-medium-high.png");
  }
}

.co2-value {
  @include flexbox(center, flex-start);
  .co2-level-img {
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    margin-right: 10px;
    &.ok-level {
      background-color: $dark-green;
    }
    &.caution-level {
      background-color: $orange;
    } 
    &.hazard-level {
      background-color: $red;
    }
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tab-block {
  @include flexbox(center, space-between);
  margin-bottom: 30px;
  .tab {
    @include flexbox(center, flex-start);
    min-height: 40px;
    text-decoration: none;
    width: 225px;
    background: $lighter-grey;
    padding: 0 20px;
    color: $grey;
    @include font-style($primary-font-extrabold, 700, 14px);
    box-shadow: $shadow;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    &.active {
      color: $white;
      background: $blue-gradient;
      box-shadow: $blue-shadow;
      .small-rectangle {
        background: url("./assets/img/small-white-rectangle.svg") no-repeat;
      }
      .big-rectangle {
        background: url("./assets/img/big-white-rectangle.svg") no-repeat;
      }
    }
    &:hover {
      color: $white;
      background: $blue-gradient;
      box-shadow: $blue-shadow;
      .small-rectangle {
        background: url("./assets/img/small-white-rectangle.svg") no-repeat;
      }
      .big-rectangle {
        background: url("./assets/img/big-white-rectangle.svg") no-repeat;
      }
    }
    .small-rectangle {
      display: block;
      width: 14px;
      height: 14px;
      background: url("./assets/img/small-grey-rectangle.svg") no-repeat;
      margin-right: 8px;
    }
    .big-rectangle {
      display: block;
      width: 22px;
      height: 24px;
      background: url("./assets/img/big-grey-rectangle.svg") no-repeat;
      margin-right: 8px;
    }
  }
}
.sample-block {
  .sample-btn {
    display: inline-block;
    border: 2px solid #17bcf1;
    border-radius: 5px;
    padding: 17px 42px 17px 20px;
    background-color: transparent;
    background-image: url("./assets/img/download-icon.svg");
    background-repeat: no-repeat;
    background-position: 124px center;
    color: #17bcf1;
    text-transform: uppercase;
    @include font-style($primary-font-extrabold, 700, 14px);
    cursor: pointer;
    margin-bottom: 30px;
  }
  .popup-map_children__title, .blue-block {
    margin-bottom: 10px!important;
  }
  .size-format {
    margin-top: 10px!important;
  }
  .map-popup-body {
    height: 480px;
    @include flexbox(stretch, space-between, column);
  }
  .successful-block {
    margin-top: 20px;
    p {
      @include font-style($primary-font-semibold, 600, 16px);
      color: #0FC18C;
    }
  }
  .errors-block {
    height: 160px;
    overflow-y: auto;
    margin-top: 20px;
    .error-code {
      padding-left: 60px;
      margin-bottom: 15px;
      background: url("./assets/img/error-triangle.svg") no-repeat center left;
      min-height: 25px;
      p {
        @include font-style($primary-font-semibold, 500, 14px);
        color: #E45B55;
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.сustom-map-width, .device-page {
  .legend-block {
    @include flexbox(center, flex-start);
    margin-top: 30px;
    &_item {
      @include flexbox(center, flex-start);
      margin-right: 20px;
      p {
        @include font-style($primary-font-extrabold, 700, 10px, 12px);
        color: $grey;
        margin-left: 6px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      img {
        max-width: 16px;
      }
    }
    &_item:last-child {
      margin-right: 0;
    }
  }
}

.tabs {
  padding: 0 30px;
  @include flexbox(center, flex-start);
  position: relative;
  &_link {
    position: relative;
    @include font-style($primary-font-semibold, 700, 14px, 16px);
    color: $grey;
    padding: 16px 20px 10px 0;
    border-bottom: 2px solid $lighter-grey;
    transition: .3s ease;
    cursor: pointer;
    z-index: 5;
    &:hover {
      color: $dark-grey;
      border-bottom: 2px solid $blue;
    }
  }
  .active {
    color: $dark-grey;
    border-bottom: 2px solid $blue;
  }
  .border-line {
    position: absolute;
    right: 30px;
    left: 30px;
    bottom: 0;
    height: 2px;
    border-bottom: 2px solid $lighter-grey;
  }
}