.table {
  max-width: 1900px;
  min-height: 500px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  .table-head {
    position: relative;
    width: fit-content;
    @include flexbox(stretch, flex-start);
    background: $lighter-grey;
    padding: 16px 20px 16px 20px;
    margin-bottom: 8px;
    box-shadow: $shadow;
    border-radius: 10px;
    .table-head_item {
      @include font-style($primary-font-bold, 700, 14px);
      @include flexbox(center, flex-start, row, nowrap);
      box-sizing: border-box;
      line-height: 22px;
      color: $grey;
      padding: 0 10px;
      &:first-child {
        padding: 0 10px 0 0;
      }
      &.state {
        padding: 0;
      }
      &.columns {
        background: url("./assets/img/settings.png") no-repeat center right 0;
        background-size: contain;
        padding-right: 20px;
        cursor: pointer;
      }
      &.columns-wide {
        @include flexbox(center, flex-end, row, nowrap);
        padding: 0;
        width: 215px;
        min-width: 215px;
      }
      &.select-all {
        cursor: pointer;
        width: 24px;
        height: 24px;
        padding: 0;
        margin-right: 15px;
        background: url("./assets/img/empty-square.svg") no-repeat center;
        background-size: contain;
        &.active {
          background: url("./assets/img/active-square.svg") no-repeat center;
          background-size: contain;
        }
      }
    }
    .column-popup {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      background: $lightest-grey;
      border-radius: 10px;
      padding: 20px 30px;
      width: 240px;
      box-shadow: 0px 5px 15px rgba(185, 185, 202, 0.5);
      &_body {
        .btns-block {
          margin-top: 20px;
        }
      }
      .column-popup-header {
        @include flexbox;
        margin-bottom: 15px;
        div {
          width: 50%;
          @include font-style($primary-font-bold, 700, 12px);
          color: $grey;
          padding: 0 0 10px 6px;
          border-bottom: 2px solid $grey;
          cursor: pointer;
          opacity: 0.5;
        }
        .active {
          opacity: 1;
        }
      }
      .column-popup_main {
        height: 200px;
        overflow-y: auto;
      }
      .search-block {
        margin-bottom: 15px;
        position: relative;
        .column-search {
          background: $lighter-grey;
          box-shadow: inset 0px -1px 0px $darker-white, inset 0px 1px 1px rgba(217, 218, 219, 0.3);
          border-radius: 5px;
          @include font-style($primary-font-semibold, 600, 12px);
          color: $dark-grey;
          width: 100%;
          border: none;
          outline: none;
          height: 22px;
        }
        .search-btn {
          display: inline-block;
          height: 15px;
          width: 15px;
          background: url("./assets/img/search.svg") center no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          margin-top: -8px;
          right: 10px;
          cursor: pointer;
        }
      }
      .custom-checkbox-block {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .save-btn {
        margin-right: 15px;
      }
      .save-btn, .cancel-btn {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  .columns {
    width: 125px;
    max-width: 125px;
  }
  .columns-wide {
    padding: 0;
    width: 215px;
    min-width: 215px;
  }
  .name, .deviceEUI, .deviceType, .siteName, .siteId, .deviceName {
    width: 200px;
  }
  .tags {
    width: 300px;
  }
  .state {
    width: 110px;
    text-align: center;
  }
  .status {
    width: 110px;
  }
  .table-body_row {
    width: fit-content;
    @include flexbox(center, flex-start);
    background: $white;
    padding: 15px 20px 15px 20px;
    box-shadow: $shadow;
    border-radius: 10px;
    margin-bottom: 8px;
    border: 2px solid transparent;
    .table-body_item {
      @include font-style($secondary-font-regular, 400, 16px);
      box-sizing: border-box;
      padding: 10px;
      color: $grey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        padding: 10px 10px 10px 0;
      }
      &.state {
        @include flexbox(center, flex-start);
        padding: 5px;
        overflow: visible;
      }
      &.status {
        @include flexbox(center, flex-start);
        padding: 5px;
        overflow: visible;
      }
      &.tag {
        @include flexbox;
      }
      .tag {
        @include font-style($primary-font-semibold, 600, 12px, 14px);
        color: $dark-grey;
        margin: 0 8px 5px 0;
        box-shadow: $shadow;
        border-radius: 5px;
        padding: 10px 15px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
          margin-right: 0;
        }
      }
      .tag-color {
        width: 80px;
        height: 36px;
        border-radius: 5px;
      }
    }
    .tags {
      @include flexbox;
      flex-wrap: wrap;
    }
    .buttons {
      @include flexbox;
      padding-right: 0;
      justify-content: flex-end;
    }
    .select-item {
      cursor: pointer;
      width: 24px;
      margin-right: 15px;
      padding: 0!important;
    }
    &.active {
      background: $light-green;
    }
  }
}
.clickable {
  cursor: pointer;
}
.static-table {
  overflow-x: visible;
  .table-head, .table-body_row {
    width: auto;
  }
  .table-body_row.active {
    border: 2px solid #66beef;
    background: $white;
  }
  .table-head {
    justify-content: flex-start;
    .table-head_item {
      flex-basis: 260px;
      flex: 1;
      max-width: 450px;
      &:last-child {
        background: none;
        cursor: auto;
        padding-right: 0;
      }
    }
  }
  .table-body_row {
    justify-content: flex-start;
    .table-body_item {
      flex-basis: 260px;
      flex: 1;
      max-width: 450px;
    }
  }
}
.tag-table {
  width: 600px;
}
.custom-width-block {
  @include flexbox(center);
  .static-table {
    flex-grow: 1;
  }
  .tag-table {
    flex-grow: 0;
  }
  .empty-block {
    padding-left: 50px;
    width: 100%;
    @include flexbox(center, center);
    img {
      width: auto;
      height: auto;
    }
  }
  .map-img-block {
    width: 100%;
    padding-left: 50px;
    @include flexbox(center, center, column);
    p {
      @include font-style($primary-font-semibold, 800, 16px, 19px);
      color: $dark-grey;
      margin-bottom: 25px;
      width: 100%;
    }
    img {
      width: auto;
      max-width: 850px;
      max-height: 750px;
      @media (max-width: 1663px) {
        max-width: 850px;
      }
      @media (max-width: 1500px) {
        max-width: 700px;
      }
      @media (max-width: 1300px) {
        max-width: 650px;
      }
      @media (max-width: 1250px) {
        max-width: 615px;
      }
    }
  }
}
.сustom-map-width {
  @include flexbox(flex-start, flex-start);
}
.indoor-map {
  max-width: 650px;
  width: 100%;
  @media (max-width: 1300px) {
    max-width: 550px;
  }
}
.empty-text {
  text-align: center;
  @include font-style($primary-font-semibold, 600, 20px, 23px);
  color: $grey;
  margin-top: 50px;
}