.progress-bar {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;

    &_full {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
    }
}