.not-found-page {
    width: 605px;
    height: 542px;
    margin: 100px auto 0;
    box-sizing: border-box;
    background-image: url("./assets/img/empty.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding-top: 123px;

    .not-found-content {
        @include font-style($primary-font-black, 900, 18px);
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $dark-grey;
        text-align: center;

        .not-found-error-message {
            margin: 40px 0 80px;
        }

        .go-home-btn {
            border: none;
            padding: 20px 25px;
            background: $blue-gradient;
            box-shadow: $blue-shadow;
            border-radius: 50px;
            cursor: pointer;

            span {
                color: $white;
                vertical-align: middle;
                @include font-style($primary-font-black, 900, 16px, 20px);
                letter-spacing: 0.02em;
                text-transform: uppercase;
            }

            img {
                vertical-align: middle;
                margin-left: 10px;
            }
        }
    }
}